import React from 'react'
import './change.css'
const Change = () => {
  return (
      <div className="change__hero">
            <div className="change__text">
                <h1>Connecting a new generation of <br /> Chrisitan people globally</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur totam quo <br /> ex delectus nobis illo doloremque laborum ea quas rerum. illo doloremque laborum ea quas rerum.</p>
            </div>

            <div className="change__text-hero">
                 <h3>What is FaithMeetsLove?</h3>
                 <p>FaithMeetsLove was introduced on a college campus in 2012 and is the world’s most popular app for meeting new people.
                      It’s been downloaded more than 340 million times and is available in 190 countries and 40+ languages. </p>
                  <p>FaithMeetsLove is a place built on a world of possibility. The possibility of forming connections that could lead to more. 
                      If you’re here to meet new people, expand your social network, meet locals when you’re traveling, or just live in the now,
                       you’ve come to the right place. </p>

                       <p>aithMeetsLove is simple and fun—use the Swipe Right™ feature to Like someone, and if someone Likes you back, it's a Match!™ Get ready to enter a world of endless possibilities.</p>

                       <h3>Supported platforms and devices</h3>
                       <p>FaithMeetsLove is currently available on iOS,  Android, and HMS devices. Along with our mobile apps, you can visit FaithMeetsLove.com to use FaithMeetsLove for Web. </p>
                       <p>We also offer a lightweight version of FaithMeetsLove you can take anywhere and everywhere - download FaithMeetsLove Lite from the Google Play store. </p>
                       <p> FaithMeetsLove currently supports iOS 12.0 and up, Android 7.0 and up, and the latest versions of all major web browsers (Chrome, Firefox, Safari, Edge, etc.).</p>

                       <h3>Signing Up and Getting Started</h3>
                       <ul>
                           <li>How do I create a FaithMeetsLove account?</li>
                           <li>Can I sign up without Facebook?</li>
                           <li>How do I edit my profile?</li>
                           <li>How do I set my search preferences?</li>
                           <li>How to disable an ad blocker</li>
                           <li>How do I change my language settings on FaithMeetsLove</li>
                           <li>Referral Rewards</li>
                           <li>What happens if I’ve been age-restricted on FaithMeetsLove? </li>
                       </ul>
            </div>
      </div>
  )
}

export default Change