import React from 'react'
import './footer.css'
import faithmeetfooter from '../../assets/faithmeetfooter.png';
import { BrowserRouter, Route, Routes,Links, Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
       <div className="buddysafe__footer-content">
          <p class="bubby__footer-list">
              <Link className= "list-text" to ="/about">About</Link>
              <Link className= "list-text" to ="/services">Services</Link>
                <Link className= "list-text" to ="/press">Press</Link>
                <Link className= "list-text" to ="/support">Support</Link>
                <Link className= "list-text" to ="/contact">Contact</Link>
                <Link className= "list-text" to ="/download">Download</Link>
             </p>
       </div>
       <div className="footer-content">
     
        <ul className="socials">
            <span><a href="https://www.facebook.com/FaithMeetsLove/" target="_blank"><i className="fab fa-facebook-f facebook"></i></a></span>
            <span><a href="https://twitter.com/Faithm33tslove" target="_blank"><i className="fab fa-twitter twitter"></i></a></span>
            <span><a href="https://www.linkedin.com/company/faithmeetslove/" target="_blank"><i className="fab fa-linkedin-in linkedin"></i></a></span>
            <span><a href="https://www.instagram.com/faithmeetslove/" target="_blank"><i className="fab fa-instagram instagram"></i></a></span>

        </ul>
        <Link className= "list-text" to ="/">
            <img src={faithmeetfooter} alt="" />
        </Link>
        <p>copyright &copy; <a href="/" className="color-text-a"> FaithMeetsLove</a>  </p>
    </div>
</footer>
  )
}
