import React from 'react'
import safe from '../../assets/Safety.svg';
import './safety.css'
import pic1 from '../../assets/pic1.png';
import pic2 from '../../assets/pic2.png';
import pic3 from '../../assets/pic3.png';
import pic4 from '../../assets/pic4.png';
import Video from '../video/Video';
import play from '../../assets/play.png';
import vidone from '../../assets/vidone.mp4';
import close from '../../assets/close.png';
function safety() {
  return (
    <div>
        <div className='Safety-conter'>
            <h1>Safety Center</h1>
            <p>BubbySafe believes in Online safety and we
                continually work to improve 
                the safety and security of your 
                experience on our platform. .</p>
        </div>

        <section className='home'>
         <div className='home-flex'>
            <h3>Lorem ipsum dolor sit amet conse <br /> ctetur adipisicing elit.</h3>
                <p>See below about the work we do and the steps you can take towards protecting yourself <br /> and securing your data.</p>    
         </div>
            <div className='flex-img'>
                <img src={safe} alt="" />
            </div>
        </section>

         <div className="containers">
              <h1>list of video That Helps</h1>
               <div className="rows">
                  {/* <div className="col">
                      <div className="feature-img">
                        <img src={pic1} className="pic-img" alt=""  />
                        <img src={play}  alt="" className="play-btn" />
                      </div>
                  </div> */}
                  {/* <div className="col">
                     <div className="small-img-row">
                       <div className="small-img">
                          <img src={pic2} alt="" />
                          <img src={play}  alt="" className="play-btn" />
                       </div>
                       <p>is simply dummy text of the printing and typesetting industry.</p>
                     </div>
                     <div className="small-img-row">
                       <div className="small-img">
                          <img src={pic3} alt="" />
                          <img src={play}  alt="" className="play-btn" />
                      
                       </div>
                       <p>is simply dummy text of the printing and typesetting industry.</p>
                     </div>
                     <div className="small-img-row">
                       <div className="small-img">
                          <img src={pic4} alt="" />
                          <img src={play}  alt="" className="play-btn" />
                       
                       </div>
                       <p>is simply dummy text of the printing and typesetting industry.</p>
                     </div>
                  </div> */}
               </div>
           </div>     
           <div className='video-player'>
           <video loop
              muted
              controls
              autoPlay
              playsinline className="video-player videos" >
              <source src={vidone} type="video/mp4" />
               </video>
             </div>   
    </div>
  )
}

export default safety
