import React from 'react'
import Article from '../article/Article';
import './press.css';
import { blog1, blog2, blog3, blog4} from './import';
import { Link } from 'react-router-dom';

const Press = () => {
  return (
     <div className="faithmeet__article-home">
            <div className="faithmeet__article section__padding">
            <div className="faithmeet__article-heading">
              <h6 className="gradient__text">LATEST NEWS</h6>
              <p>Latest industry news and FaithMeetsLove updates</p>
            </div>
        <div className="faithmeet__article-container">
          <div className="faithmeet__article-container_groupB">
          <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2021" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2022" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2022" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2022" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
          </div>
        </div>
        <div className="faithmeet__article-container one">
          <div className="faithmeet__article-container_groupB">
          <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2021" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2021" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2021" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
            <Link  to ="/subpress">
            <Article imgUrl={blog1} date="May 04, 2021" title="Garbo Launches Background Check Platform To Public and On FaithMeetsLove" />
            </Link>
          </div>
        </div>
        
        <div className="btn-Press">
          <Link  to ="/subpress">
                <button className="btn">View More </button>
          </Link>
        </div>
        </div>
     </div>
  )
}

export default Press