import React, {useState} from 'react'
import './subscription.css'

// import sub from '../../Componnet/sub/sub';

const Subscription = () => {
 const [checkboxValue,setCheckboxValue] = useState(false)
  const checkBox = () => {
    setCheckboxValue(!checkboxValue)
  };
  return (
     <div>
      <div className="containerss">
      <div className="wrapper">
      <h1 className="title">Our Pricing</h1>
      <div className="pricing">
        <p>Annually</p>
        <label className="switch">
          <input type="checkbox" name="" id="toggle" onChange={checkBox} value={checkboxValue} />
          <span className="slider"></span>
        </label>
        <p>Monthly</p>
      </div>
      <div className="pricing-main">
        <div className="pricing-box">
          <p className="box-title">Basic</p>
          {
            checkboxValue ? <p className="price-monthly"> $<span className="price">19.99</span></p>:
             <p className="price-annually"> $<span className="price">199.99</span></p>
          }
          <main classsName="list-ul">
            <h6 className="list-type-li">2 Users Allowed</h6>
            <h6 className="list-type-li">Match. Chat. Meet.</h6>
            <h6 className="list-type-li">Hide Advertisements</h6>
          </main>
          <h5 className="read-a" href="#">Start free 30-day </h5>
        </div>
        <div className="pricing-box featured">
          <p className="box-title">Professional</p>
          {
            checkboxValue ? <p className="price-monthly"> $<span className="price">19.99</span></p>:
             <p className="price-annually"> $<span className="price">199.99</span></p>
          }
          <main classsName="list-ul">
            <h6 className="list-type-li">5 Super Likes a week</h6>
            <h6 className="list-type-li">1 Free Boost a month</h6>
            <h6 className="list-type-li">See Who Likes You</h6>
          </main>
          <h5 className="read-a" href="#">Start free 30-day </h5>
        </div>
        <div className="pricing-box">
          <p className="box-title">Master</p>
          {
            checkboxValue ? <p className="price-monthly"> $<span className="price">19.99</span></p>:
             <p className="price-annually"> $<span className="price">199.99</span></p>
          }
          <main classsName="list-ul">
            <h6 className="list-type-li">New Top Picks every day</h6>
            <h6 className="list-type-li">Message before Matching</h6>
            <h6 className="list-type-li">Prioritized Likes</h6>
          </main>
          <h5 className="read-a" href="#">Start free 30-day </h5>
        </div>
      </div>
     </div>
   </div>
     <div className="text-sub">
          <div className="text__sub-text">
              <h2>Why Faithment subscription?</h2>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.
                Sit alias amet praesentium doloremque distinctio maxime accusantium eligendi, 
                unde reiciendis tenetur repellat saepe, accusamus quibusdam asperiores.</p>
          </div>
     </div>
   </div>
  )
}

export default Subscription