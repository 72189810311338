import React from 'react'
import './subpress.css';
import { blog1, blog2, blog3, blog4} from './import';
const Subpress = () => {
  return (
     <div id="blog">
        <div className="blog-heading">
        <span><i className="ri-more-2-line"></i> Recent Post</span>
        <h3> More Blog</h3>
       </div>
       {/* blog container */}
       <div className="blog-container">
            {/* box-1 */}
           <div className="blog-box">
                {/* img */}
                <div className="blog-img">
                    <img src={blog1} alt="" />
                </div>
                {/* text */}
                <div className="blog-text">
                <span> 18 Feb 2022 / Garbo Launches Background Check Platform To Public and On FaithMeetsLove</span>
                    <a href="#" className="blog-title" >What Has Happened Of All OF Them</a>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                      Illo expedita voluptatum distinctio laboriosam non veniam? 
                      Quod inventore maiores praesentium ad! Expedita cupiditate earum officiis iusto, 
                      esse laborum magni perferendis voluptatum.</p>
                </div>
           </div>

                {/* box-1 */}
                <div className="blog-box">
                {/* img */}
                <div className="blog-img">
                    <img src={blog1} alt="" />
                </div>
                {/* text */}
                <div className="blog-text">
                    <span> 18 Feb 2022 / Garbo Launches Background Check Platform To Public and On FaithMeetsLove</span>
                    <a href="#" className="blog-title" >What Has Happened Of All OF Them</a>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                      Illo expedita voluptatum distinctio laboriosam non veniam? 
                      Quod inventore maiores praesentium ad! Expedita cupiditate earum officiis iusto, 
                      esse laborum magni perferendis voluptatum.</p>
                </div>
           </div>

                {/* box-1 */}
                <div className="blog-box">
                {/* img */}
                <div className="blog-img">
                    <img src={blog1} alt="" />
                </div>
                {/* text */}
                <div className="blog-text">
                <span> 18 Feb 2022 / Garbo Launches Background Check Platform To Public and On FaithMeetsLove</span>
                    <a href="#" className="blog-title" >What Has Happened Of All OF Them</a>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                      Illo expedita voluptatum distinctio laboriosam non veniam? 
                      Quod inventore maiores praesentium ad! Expedita cupiditate earum officiis iusto, 
                      esse laborum magni perferendis voluptatum.</p>
                </div>
           </div>
       </div>
    
     </div>
  )
}

export default Subpress
