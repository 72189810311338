import React from 'react'
import './contact.css';
const Contact = () => {
  return (
    <section className="contact-section">
    <h2>Contact Us</h2>
    <p>We Might Be An App But We Don't Leave Our Customers Hanging. 
        Everything About Us Is On The App, But If You Ever Need Help (Or You Just Want To Chat), 
        We’ve Got You.!</p>
    <div className="contact-container">
        <div className="details">
            <div className="detail">
                <i className="uil uil-mobile-android"></i>
                <h3>CALL US</h3>
                 <p>+44 000-000-000</p>
            </div>
            <div className="detail">
                <i className="uil uil-envelopes"></i>
                <h3>EMAIL US</h3>
                 <p>techsupport@faithmeetslove.com</p>
            </div>
            <div className="detail">
                <i className="uil uil-location-pin-alt"></i>
                <h3>VISIT OFFICE</h3>
                 <p>United Kingdom</p>
            </div>
        </div>
        <form id="Form" action="#">
            <div className="input-name">
                <input type="text" placeholder="First Name"/>
                <input type="text" placeholder="Last Name"/>
            </div><input type="email" placeholder="Your Email"/>
            <textarea id="" cols="20" rows="5" placeholder="Your Massage"></textarea>
            <a href="#" className="btn contact-btn">SUBMIT</a>
        </form>
    </div>
</section>

  )
}

export default Contact
