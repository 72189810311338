import { BrowserRouter, Route, Routes, Links } from "react-router-dom";
import Footer from "./container/footer/Footer";
import Header from './container/header/Header';
import Contact from './Componnet/contact/Contact';
import Support from "./Componnet/support/Support";
import Terms from "./Componnet/terms/Terms";
import Safety from  "./Componnet/safety/Safety";
import Cookie from  "./Componnet/cookie/Cookie";
import  Privacy from  "./Componnet/privacy/Privacy";
import  Professional from  "./Componnet/professional/Professional";
import Change  from  "./Componnet/change/Change";
import  Visibility from  "./Componnet/visibility/Visibility"
import  Setting from  "./Componnet/setting/Setting";
import  Security from  "./Componnet/security/Security";
import Subscription  from "./Componnet/subscription/Subscription";
import About from "./Componnet/about/About";
import Press from "./Componnet/press/Press";
import Faq from "./Componnet/faq/Faq";
import Download from "./Componnet/download/Download";
import Services from "./Componnet/services/Services";
import Eula from "./Componnet/eula/Eula";
import Read from "./Componnet/read/Read";
import Subpress from "./Componnet/subpress/Subpress";
function App() {
  return (
    <div>
    <BrowserRouter>
    <Routes>
         <Route path = "/" element = {<Header/>}></Route>
          <Route path = "/footer" element = {<Footer/>}></Route>
           <Route path = "/contact" element = {<Contact/>}></Route>
          <Route path = "/support" element = {<Support/>}></Route>
          <Route path = "terms" element = {<Terms/>}></Route>
          <Route path = "safety" element = {<Safety/>}></Route>
          <Route path = "cookie" element = {<Cookie/>}></Route>
          <Route path = "privacy" element ={<Privacy/>}></Route>
          <Route path = "professional" element ={<Professional/>}></Route>
          <Route path = "change" element ={<Change/>}></Route>
          <Route path = "visibility" element ={<Visibility/>}></Route>
          <Route path = "setting" element ={<Setting/>}></Route>
          <Route path = "security"  element ={<Security/>}></Route>
          <Route path = "subscription" element ={<Subscription/>}></Route>
          <Route path = "about" element = {<About/>}></Route>
          <Route path = "press" element ={<Press/>}></Route>
          <Route path = "faq" element ={<Faq/>}></Route>
          <Route path ="download" element ={<Download/>}></Route>
          <Route path ="services" element ={<Services/>}></Route>
          <Route path ="eula" element ={<Eula/>}></Route> 
          <Route path ="read" element  ={<Read/>}></Route>
          <Route path ="subpress" element  ={<Subpress/>}></Route>
    </Routes>
    <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
