import React from 'react'
import './read.css'
import read from '../../assets/Read.jpg';
const Read = () => {
  return (
    <div className="read-more">
         <div className="news">
             <h6>Company News <span>Mar 03, 2022</span> </h6>
              <h1>FaithMeetsLove Is The Future. Let Us Exlore How It Is?</h1>
              <p className="first-p">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque
                 repellendus unde aspernatur nesciunt tempore quis.</p>
                <img className="read-img" src={read} alt="" />
                <p className="first-pp">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel odio, maiores incidunt quas
                   expedita veniam illum reprehenderit beatae dolorum perferendis aut facere deserunt! Illum maxime
                    sed alias similique aut ullam! Illo aliquid 
                   consequatur autem nemo ab distinctio?  Nesciunt eveniet laboriosam consectetur velit quibusdam 
                   illum dolore sunt ducimus, natus tempore expedita suscipit ea assumenda, voluptatem obcaecati repellat
                    earum aut cum? Quam vitae iusto mollitia nihil omnis magnam quo! Illum commodi possimus, accusamus repudiandae illo
                   dolore quasi sint, quaerat veniam provident nihil beatae numquam dolorum reprehenderit nostrum accusantium quam tenetur hic officia, 
                   maxime eum adipisci voluptas similique obcaecati. Odio unde eius officiis.</p>

                   <p className="p-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel odio, maiores incidunt quas
                   expedita veniam illum reprehenderit beatae dolorum perferendis aut facere deserunt! Illum maxime
                    sed alias similique aut ullam! Illo aliquid 
                   consequatur autem nemo ab distinctio?  Nesciunt eveniet laboriosam consectetur velit quibusdam 
                   illum dolore sunt ducimus, natus tempore expedita suscipit ea assumenda, voluptatem obcaecati repellat
                    earum aut cum? Quam vitae iusto mollitia nihil omnis magnam quo! Illum commodi possimus, accusamus repudiandae illo
                   dolore quasi sint, quaerat veniam provident nihil beatae numquam dolorum reprehenderit nostrum accusantium quam tenetur hic officia, 
                   maxime eum adipisci voluptas similique obcaecati. Odio unde eius officiis.</p>
         </div>
    </div>
  )
}

export default Read
