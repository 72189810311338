import React from 'react'
import people from '../../assets/people.png';
import discover from '../../assets/download.jpeg';
import Google from '../../assets/Google Play.png';
import  App from '../../assets/IOS.png';
import Downloadone from '../../assets/downloadone.jpg';
import Downloadtwo from '../../assets/downloadtwo.jpg';
import Downloadthree from '../../assets/downloadhree.jpg';
import DownloadImage from '../../assets/download.jpeg';
import './download.css';
const Download = () => {
  return (
    <div>
       <div className="Faithmeet__header section__padding">
       <div className="Faithmeet__header-content">
      <h1 className="Faithmeet__text">Find Love In Faith</h1>

      <div className="Faithmeet__header-content__input">
          <a href="https://testflight.apple.com/join/9OWvhOFS"><button type="button">Download FaithMeetsLove App</button></a>
      </div>

      <div className="Faithmeet__header-content__people">
        <img src={people} />
        <p>160,000 people have download</p>
      </div>
      <div className="Faithmeet_appstore">
           <a href="https://play.google.com/store/apps/details?id=com.FaithMeetsLove.FaithMeetsLove" target="_blank"> <img className="img-one" src={Google} alt="" /></a>
           <a href="https://testflight.apple.com/join/9OWvhOFS" target="_blank"> <img className="img-one"  src={App} alt="" /></a>     
      </div>
    </div>

    <div className="Faithmeet__header-image">
      <img src={discover} />
     </div>
    </div>
      
      <div className="gallery-section">
           <div className="gallery-wrap">
              <div className="gallery-images">
                  <div className="gallery">
                     <div className="image-section">
                     <img src={Downloadone} alt="" />
                     </div>
                  </div>
                  <div className="gallery">
                     <div className="image-section">
                         <img src={Downloadtwo} alt="" />
                     </div>
                  </div>
                  <div className="gallery">
                     <div className="image-section">
                     <img src={Downloadthree} alt="" />
                     </div>
                  </div>
                  <div className="gallery">
                     <div className="image-section">
                         <img src={Downloadone} alt="" />
                     </div>
                  </div>
                  <div className="gallery">
                     <div className="image-section">
                     <img src={Downloadthree} alt="" />
                     </div>
                  </div>
                  <div className="gallery">
                     <div className="image-section">
                         <img src={Downloadtwo} alt="" />
                     </div>
                  </div>
              </div>
            </div> 
      </div>

    <div className="download-word">
    <div className="download-text">
         <h2>Supported Platforms and Devices</h2>
         <p>FaithMeetsLove is currently available on  <span> iOS, Android, 
           cross-platform devices.</span> Along with our mobile apps,
            you can visit Faithmeetslove.com to use FaithMeetsLove Web App. 
            We also offer tablet version of the FaithMeetsLove App that you can take anywhere and everywhere.</p>
             <p>download FaithMeetsLove App from the App stores today.</p>
     </div>
    </div>
  </div>
  )
}

export default Download